<template>
    <div>
        <Slider ratio="10by1" :image="$options.filters.cdnUrl(firstSlide.image)" :title="$t('menu.about')" />

        <div class="theme-light bg-white pagebg">

            <Articles :articles="articles" class="section-margin-top-small" />

            <div class="c w-xxl section-margin-top-small" v-if="team.length">
                <SectionTitle :title="$t('menu.team')" />

                <div class="flex -wrap columns news mt-xxl">
                    <div v-for="member in team" :key="member.id" class="col-12 col-6__s-up col-4__m-up col-3__l-up mb-xl">
                        <Highlight :title="member.name" :image="member.image ? {
                            path: $options.filters.cdnUrl(member.image),
                            ratio: '4by3',
                        } : false">
                            <template v-slot:content>
                                {{ member['function_' + lang] }}
                                <div class="team__contact mt-l size-6">
                                    <div class="flex -align-center" v-if="member.email">
                                        <icon class="mdi mdi-email-outline mr-r" />
                                        <a :href="'mailto:' + member.email">
                                            {{ member.email }}
                                        </a>
                                    </div>

                                    <div class="flex -align-center" v-if="member.phone">
                                        <icon class="mdi mdi-phone mr-r" />
                                        <a :href="'tel:' + member.phone">
                                            {{ member.phone }}
                                        </a>
                                    </div>
                                </div>
                            </template>
                        </Highlight>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    components: {
        Articles: () => import('@/components/Articles.vue'),
        Highlight: () => import('@/components/Highlight.vue'),
        SectionTitle: () => import('@/components/SectionTitle.vue'),
        Slider: () => import('@/components/Slider.vue'),
    },
    data() {
        return {
            articles: [],
            team: [],
        };
    },

    computed: {
        lang() {
            return this.$store.getters['Language/current'];
        },

        firstSlide() {
            if (this.$store.state.data.slides && this.$store.state.data.slides.length > 0) {
                return this.$store.state.data.slides[0];
            }
            return {};
        },
    },

    mounted() {
        this.$store.dispatch('get', 'items/about').then((r) => {
            this.articles = r;
        });
        this.$store.dispatch('get', 'items/team').then((r) => {
            this.team = r;
        });
    },
};
</script>
